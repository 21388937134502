import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "ilSlots",
    "ilSlotOptions",
    "rookieSlots",
    "rookieSlotOptions",
    "vetSlots",
    "vetSlotOptions",
  ];

  connect() {
    if (this.hasIlSlotsTarget) {
      this.toggleIlSlotOptions();
      this.observeChanges(
        this.ilSlotsTarget,
        this.toggleIlSlotOptions.bind(this),
      );
    }
    if (this.hasRookieSlotsTarget) {
      this.toggleRookieSlotOptions();
      this.observeChanges(
        this.rookieSlotsTarget,
        this.toggleRookieSlotOptions.bind(this),
      );
    }
    if (this.hasVetSlotsTarget) {
      this.toggleVetSlotOptions();
      this.observeChanges(
        this.vetSlotsTarget,
        this.toggleVetSlotOptions.bind(this),
      );
    }
  }

  observeChanges(target, callback) {
    if (target) {
      target.addEventListener("input", callback);
      target.addEventListener("change", callback);
    }
  }

  toggleIlSlotOptions() {
    if (this.hasIlSlotsTarget && this.hasIlSlotOptionsTarget) {
      const ilSlotsValue = parseInt(this.ilSlotsTarget.value, 10);
      this.ilSlotOptionsTarget.style.display =
        ilSlotsValue > 0 ? "block" : "none";
    }
  }

  toggleRookieSlotOptions() {
    if (this.hasRookieSlotsTarget && this.hasRookieSlotOptionsTarget) {
      const rookieSlotsValue = parseInt(this.rookieSlotsTarget.value, 10);
      this.rookieSlotOptionsTarget.style.display =
        rookieSlotsValue > 0 ? "block" : "none";
    }
  }

  toggleVetSlotOptions() {
    if (this.hasVetSlotsTarget && this.hasVetSlotOptionsTarget) {
      const vetSlotsValue = parseInt(this.vetSlotsTarget.value, 10);
      this.vetSlotOptionsTarget.style.display =
        vetSlotsValue > 0 ? "block" : "none";
    }
  }
}
