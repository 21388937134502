import { startStimulusApp } from "@symfony/stimulus-bridge";
import Notification from "@stimulus-components/notification";
import Clipboard from "@stimulus-components/clipboard";
import ScrollTo from "@stimulus-components/scroll-to";

// Registers Stimulus controllers from controllers.json and in the controllers/ directory
export const app = startStimulusApp(
  require.context(
    "@symfony/stimulus-bridge/lazy-controller-loader!./controllers",
    true,
    /\.[jt]sx?$/,
  ),
);

// register any custom, 3rd party controllers here
// app.register('some_controller_name', SomeImportedController);
app.register("notification", Notification);
app.register("clipboard", Clipboard);
app.register("scroll-to", ScrollTo);
