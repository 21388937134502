import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container"];

  connect() {
    this.injectIframe(this.isDarkMode());
    this.setupThemeListener();
  }

  isDarkMode() {
    const savedTheme = localStorage.getItem("color-theme");
    if (savedTheme === "dark") {
      return true;
    } else if (savedTheme === "light") {
      return false;
    } else {
      return (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      );
    }
  }

  setupThemeListener() {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (e) => {
        if (!("color-theme" in localStorage)) {
          this.updateTheme(e.matches);
        }
      });

    document.addEventListener("themeChanged", (e) =>
      this.updateTheme(e.detail.isDark),
    );
  }

  injectIframe(isDark) {
    const container = this.containerTarget;
    const baseUrl =
      "https://share.transistor.fm/e/the-keeper-league-afl-fantasy-podcast/latest";
    const darkTheme = "color=ffffff&background=1e2124";
    const lightTheme = "color=000000&background=ffffff";
    const src = `${baseUrl}?${isDark ? darkTheme : lightTheme}`;

    const iframe = document.createElement("iframe");
    iframe.width = "100%";
    iframe.height = "180";
    iframe.src = src;

    container.innerHTML = ""; // Clear any existing content
    container.appendChild(iframe);
  }

  updateTheme(isDark) {
    this.injectIframe(isDark);
  }
}
