import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "clearButton", "spinner"];
  static values = { loading: Boolean };

  connect() {
    this.debouncedUpdate = this.debounce(this.updateFrame.bind(this), 300);
    this.toggleClearButton();
    this.setupFrameListeners();
  }

  setupFrameListeners() {
    const frame = document.getElementById("stat-content");
    if (frame) {
      frame.addEventListener("turbo:frame-render", () => {
        this.loadingValue = false;
        this.toggleClearButton();
      });
    }
  }

  search() {
    this.toggleClearButton();
    if (this.inputTarget.value.length >= 1) {
      this.debouncedUpdate();
    } else if (this.inputTarget.value.length === 0) {
      this.updateFrame(); // Clear results if input is empty
    }
  }

  clear() {
    this.inputTarget.value = "";
    this.toggleClearButton();
    this.updateFrame();
  }

  toggleClearButton() {
    if (this.hasClearButtonTarget && this.hasSpinnerTarget) {
      const showClear = this.inputTarget.value.length > 0 && !this.loadingValue;
      this.clearButtonTarget.classList.toggle("hidden", !showClear);
      this.spinnerTarget.classList.toggle("hidden", !this.loadingValue);
    }
  }

  updateFrame() {
    const searchTerm = this.inputTarget.value;
    const url = new URL(window.location);

    if (searchTerm && searchTerm.length >= 1) {
      url.searchParams.set("search", searchTerm);
    } else {
      url.searchParams.delete("search");
    }

    history.pushState({}, "", url);

    const frame = document.getElementById("stat-content");
    if (frame) {
      this.loadingValue = true;
      this.toggleClearButton();

      frame.src = url.toString();
    }
  }

  debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }
}
